.PrintBox {
    background-color: #EBEBEA;
    padding: 16px;
    width: 100%;
    max-width: 7.2cm;
    box-shadow: 0 6px 16px -6px black;
}

.PrintBox span {
    font-size: 13px;
}

.PrintBox .printable {
    display: none;
}

@page {
    size: auto;
    margin: 0 !important;
    padding: 0 !important;
}

@media print {
    body * {
        visibility: hidden;
        padding: 0 !important;
    }

    .PrintBox .printable {
        display: block !important;
    }

    .PrintBox,
    .PrintBox * {
        visibility: visible !important;
    }

    .PrintBox {
        position: absolute;
        top: 0;
        left: 0;
        border: none;
        margin-bottom: 0;
        width: 7.6cm;
        padding: 24px 0;
        padding-left: 0.5cm !important;
        box-shadow: none;
        color: black;
    }

    .PrintBox .bottom-spacer {
        page-break-after: always;
        page-break-inside: avoid;
        position: relative;position: relative;
    }

    .PrintBox svg {
        filter: brightness(0%);
    }

}
